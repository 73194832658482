export default {
    "en": {
        "(and {count} more error)": "(and {count} more error)",
        "(and {count} more errors)": "(and {count} more errors)",
        "1 star": "1 star",
        "1x": "1x",
        "2 stars": "2 stars",
        "2x": "2x",
        "3 stars": "3 stars",
        "3x": "3x",
        "4 stars": "4 stars",
        "5 stars": "5 stars",
        "404 - Page not found": "404 - Page not found",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{count} characters": "{count} characters",
        "About us": "About us",
        "Active": "Active",
        "Add in {day}": "Add in {day}",
        "Add moment": "Add moment",
        "All rights reserved.": "All rights reserved.",
        "Alt text": "Alt text",
        "Always show banner": "Always show banner",
        "Anchor Name": "Anchor Name",
        "Author": "Author",
        "Background image": "Background image",
        "Back to home": "Back to home",
        "BMI": "BMI",
        "Capacity": "Capacity",
        "Choose a date and time": "Choose a date and time",
        "City": "City",
        "Company name": "Company name",
        "Concept in only visible to logged in users, draft is not visible to anyone": "Concept in only visible to logged in users, draft is not visible to anyone",
        "Contact": "Contact",
        "Content": "Content",
        "Custom": "Custom",
        "Customer": "Customer",
        "Customers": "Customers",
        "Dashboard URL": "Dashboard URL",
        "Date and Time": "Date and Time",
        "Date and time": "Date and time",
        "Date of birth": "Date of birth",
        "Day": "Day",
        "Description": "Description",
        "description": "description",
        "Discount": "Discount",
        "Discount active": "Discount active",
        "E-mail": "E-mail",
        "E-mail *": "E-mail *",
        "Edit Page": "Edit Page",
        "Email address": "Email address",
        "End": "End",
        "Excerpt": "Excerpt",
        "excerpt": "excerpt",
        "Fat percentage": "Fat percentage",
        "First Name": "First Name",
        "First name": "First name",
        "Forbidden": "Forbidden",
        "Gender": "Gender",
        "Google Maps URL": "Google Maps URL",
        "Google maps URL": "Google maps URL",
        "Go to page {page}": "Go to page {page}",
        "header bg": "header bg",
        "Hello!": "Hello!",
        "Hey Topper, what's your name?": "Hey Topper, what's your name?",
        "Hi,": "Hi,",
        "Hits": "Hits",
        "Hi [name] !": "Hi [name] !",
        "How can I help you?": "How can I help you?",
        "How can I reach you?": "How can I reach you?",
        "I am Danny, who are you?": "I am Danny, who are you?",
        "If a interview needs to be rescheduled, either select a new timeslot or create a new one using the plus button.": "If a interview needs to be rescheduled, either select a new timeslot or create a new one using the plus button.",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "If enabled, and no image is uploaded, the banner from the homepage will be shown.",
        "If in page block anchor ID is filled in, fill in te excact name starting with a #. For example if the block is named quote, the url that goes in here would be #quote. On click link it will scroll to this block.": "If in page block anchor ID is filled in, fill in te excact name starting with a #. For example if the block is named quote, the url that goes in here would be #quote. On click link it will scroll to this block.",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:",
        "If you did not create an account, no further action is required.": "If you did not create an account, no further action is required.",
        "If you did not request a password reset, no further action is required.": "If you did not request a password reset, no further action is required.",
        "Image": "Image",
        "Index Page": "Index Page",
        "In kilograms (ex 80,5).": "In kilograms (ex 80,5).",
        "In meters (ex 1,80).": "In meters (ex 1,80).",
        "Instagram": "Instagram",
        "Instagram handle": "Instagram handle",
        "Interview moment": "Interview moment",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "It is recommended to keep the {attribute} between {min} and {max} characters long",
        "I will contact you within 24 hours.": "I will contact you within 24 hours.",
        "Last Name": "Last Name",
        "Last name": "Last name",
        "Leave empty for no end date": "Leave empty for no end date",
        "Leave empty to use current time.": "Leave empty to use current time.",
        "Leave empty to use parent details": "Leave empty to use parent details",
        "Length": "Length",
        "Lifestyle": "Lifestyle",
        "List items": "List items",
        "Logbook": "Logbook",
        "Login": "Login",
        "Logout": "Logout",
        "Look ahead days": "Look ahead days",
        "Lose weight": "Lose weight",
        "Made by:": "Made by:",
        "Men": "Men",
        "mobile menu bg": "mobile menu bg",
        "Moment": "Moment",
        "Month": "Month",
        "Motivation": "Motivation",
        "Muscle building": "Muscle building",
        "Muscle percentage": "Muscle percentage",
        "My logbook": "My logbook",
        "Name": "name",
        "name": "name",
        "New URL": "New URL",
        "Next": "Next",
        "Not Found": "Not Found",
        "of": "of",
        "Old URL": "Old URL",
        "Openings hours": "Openings hours",
        "Open url in new tab": "Open url in new tab",
        "Other": "Other",
        "Package": "Package",
        "Package frequency": "Package frequency",
        "Package name": "Package name",
        "Packages": "Packages",
        "Page Expired": "Page Expired",
        "Pagination Navigation": "Pagination Navigation",
        "Parent category": "Parent category",
        "Permanent": "Permanent",
        "Phone": "Phone",
        "Phone number": "Phone number",
        "Phone number *": "Phone number *",
        "Please click the button below to verify your email address.": "Please click the button below to verify your email address.",
        "Postal code": "Postal code",
        "Previous": "Previous",
        "Price": "Price",
        "Price frequency": "Price frequency",
        "Privacy declaration": "Privacy declaration",
        "Quote": "Quote",
        "Reason": "Reason",
        "Regards": "Regards",
        "Register": "Register",
        "Reset Password": "Reset Password",
        "Reset Password Notification": "Reset Password Notification",
        "Restart": "Restart",
        "results": "results",
        "Review": "Review",
        "Reviews": "Reviews",
        "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created": "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created",
        "Send": "Send",
        "SEO Settings for {lang}": "SEO Settings for {lang}",
        "SEO title": "SEO title",
        "Server Error": "Server Error",
        "Service Unavailable": "Service Unavailable",
        "Shall we schedule an intake meeting right away?": "Shall we schedule an intake meeting right away?",
        "Showing": "Showing",
        "Show Request quote and download leaflet buttons": "Show Request quote and download leaflet buttons",
        "Slug": "Slug",
        "Stars": "Stars",
        "Start": "Start",
        "Statistics": "Statistics",
        "Steps": "Steps",
        "Street name": "Street name",
        "Sub menu will only be shown if url is not filled in": "Sub menu will only be shown if url is not filled in",
        "Terms and Conditions": "Terms and Conditions",
        "Text": "Text",
        "Thank you for filling in!": "Thank you for filling in!",
        "Thank you for your Intake": "Thank you for your Intake",
        "The {attribute} must contain at least one letter.": "The {attribute} must contain at least one letter.",
        "The {attribute} must contain at least one number.": "The {attribute} must contain at least one number.",
        "The {attribute} must contain at least one symbol.": "The {attribute} must contain at least one symbol.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "The {attribute} must contain at least one uppercase and one lowercase letter.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.",
        "The given data was invalid.": "The given data was invalid.",
        "This anchor ID is used for the menu to scroll to this block. Fill in a short name, without spaces.": "This anchor ID is used for the menu to scroll to this block. Fill in a short name, without spaces.",
        "This link has expired. Please request a new one.": "This link has expired. Please request a new one.",
        "This password reset link will expire in {count} minutes.": "This password reset link will expire in {count} minutes.",
        "Timeslot": "Timeslot",
        "Timeslot is no longer available": "Timeslot is no longer available",
        "Title": "Title",
        "title": "title",
        "to": "to",
        "Toggle navigation": "Toggle navigation",
        "Too Many Requests": "Too Many Requests",
        "Type": "Type",
        "Unauthorized": "Unauthorized",
        "url": "url",
        "Variants": "Variants",
        "Verify Email Address": "Verify Email Address",
        "Web Page": "Web Page",
        "weekly": "weekly",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.",
        "Weight": "Weight",
        "What is your birthday?": "What is your birthday?",
        "What is your gender?": "What is your gender?",
        "What is your motivation?": "What is your motivation?",
        "When enabled, an email will be sent to the user, letting them know their account has been created.": "When enabled, an email will be sent to the user, letting them know their account has been created.",
        "Whoops!": "Whoops!",
        "Why are you motivated to get started?": "Why are you motivated to get started?",
        "Woman": "Woman",
        "Year": "Year",
        "You are receiving this email because we received a password reset request for your account.": "You are receiving this email because we received a password reset request for your account.",
        "Your account for {siteName}": "Your account for {siteName}",
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "accepted_if": "The {attribute} must be accepted when {other} is {value}.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} must only contain letters.",
            "alpha_dash": "The {attribute} must only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} must only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "array": "The {attribute} must have between {min} and {max} items.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "numeric": "The {attribute} must be between {min} and {max}.",
                "string": "The {attribute} must be between {min} and {max} characters."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "current_password": "The password is incorrect.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "declined": "The {attribute} must be declined.",
            "declined_if": "The {attribute} must be declined when {other} is {value}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "doesnt_end_with": "The {attribute} may not end with one of the following: {values}.",
            "doesnt_start_with": "The {attribute} may not start with one of the following: {values}.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "enum": "The selected {attribute} is invalid.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "array": "The {attribute} must have more than {value} items.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "numeric": "The {attribute} must be greater than {value}.",
                "string": "The {attribute} must be greater than {value} characters."
            },
            "gte": {
                "array": "The {attribute} must have {value} items or more.",
                "file": "The {attribute} must be greater than or equal to {value} kilobytes.",
                "numeric": "The {attribute} must be greater than or equal to {value}.",
                "string": "The {attribute} must be greater than or equal to {value} characters."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lowercase": "The {attribute} must be lowercase.",
            "lt": {
                "array": "The {attribute} must have less than {value} items.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "numeric": "The {attribute} must be less than {value}.",
                "string": "The {attribute} must be less than {value} characters."
            },
            "lte": {
                "array": "The {attribute} must not have more than {value} items.",
                "file": "The {attribute} must be less than or equal to {value} kilobytes.",
                "numeric": "The {attribute} must be less than or equal to {value}.",
                "string": "The {attribute} must be less than or equal to {value} characters."
            },
            "mac_address": "The {attribute} must be a valid MAC address.",
            "max": {
                "array": "The {attribute} must not have more than {max} items.",
                "file": "The {attribute} must not be greater than {max} kilobytes.",
                "numeric": "The {attribute} must not be greater than {max}.",
                "string": "The {attribute} must not be greater than {max} characters."
            },
            "max_digits": "The {attribute} must not have more than {max} digits.",
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "array": "The {attribute} must have at least {min} items.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "numeric": "The {attribute} must be at least {min}.",
                "string": "The {attribute} must be at least {min} characters."
            },
            "min_digits": "The {attribute} must have at least {min} digits.",
            "multiple_of": "The {attribute} must be a multiple of {value}.",
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": {
                "letters": "The {attribute} must contain at least one letter.",
                "mixed": "The {attribute} must contain at least one uppercase and one lowercase letter.",
                "numbers": "The {attribute} must contain at least one number.",
                "symbols": "The {attribute} must contain at least one symbol.",
                "uncompromised": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}."
            },
            "present": "The {attribute} field must be present.",
            "prohibited": "The {attribute} field is prohibited.",
            "prohibited_if": "The {attribute} field is prohibited when {other} is {value}.",
            "prohibited_unless": "The {attribute} field is prohibited unless {other} is in {values}.",
            "prohibits": "The {attribute} field prohibits {other} from being present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_array_keys": "The {attribute} field must contain entries for: {values}.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_if_accepted": "The {attribute} field is required when {other} is accepted.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "array": "The {attribute} must contain {size} items.",
                "file": "The {attribute} must be {size} kilobytes.",
                "numeric": "The {attribute} must be {size}.",
                "string": "The {attribute} must be {size} characters."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}.",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid timezone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "uppercase": "The {attribute} must be uppercase.",
            "url": "The {attribute} must be a valid URL.",
            "uuid": "The {attribute} must be a valid UUID.",
            "attributes": {
                "address": "address",
                "age": "age",
                "amount": "amount",
                "area": "area",
                "available": "available",
                "birthday": "birthday",
                "body": "body",
                "city": "city",
                "content": "content",
                "country": "country",
                "created_at": "created at",
                "creator": "creator",
                "current_password": "current password",
                "date": "date",
                "date_of_birth": "date of birth",
                "day": "day",
                "deleted_at": "deleted at",
                "description": "description",
                "district": "district",
                "duration": "duration",
                "email": "email",
                "excerpt": "excerpt",
                "filter": "filter",
                "first_name": "first name",
                "gender": "gender",
                "group": "group",
                "hour": "hour",
                "image": "image",
                "last_name": "last name",
                "lesson": "lesson",
                "line_address_1": "line address 1",
                "line_address_2": "line address 2",
                "message": "message",
                "middle_name": "middle name",
                "minute": "minute",
                "mobile": "mobile",
                "month": "month",
                "name": "name",
                "national_code": "national code",
                "number": "number",
                "password": "password",
                "password_confirmation": "password confirmation",
                "phone": "phone",
                "photo": "photo",
                "postal_code": "postal code",
                "price": "price",
                "province": "province",
                "recaptcha_response_field": "recaptcha response field",
                "remember": "remember",
                "restored_at": "restored at",
                "result_text_under_image": "result text under image",
                "role": "role",
                "second": "second",
                "sex": "sex",
                "short_text": "short text",
                "size": "size",
                "state": "state",
                "street": "street",
                "student": "student",
                "subject": "subject",
                "teacher": "teacher",
                "terms": "terms",
                "test_description": "test description",
                "test_locale": "test locale",
                "test_name": "test name",
                "text": "text",
                "time": "time",
                "title": "title",
                "updated_at": "updated at",
                "username": "username",
                "year": "year"
            }
        },
        "routes": {
            "terms": "terms"
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "password": "The password is incorrect.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "pagination": {
            "next": "Next &raquo;",
            "previous": "&laquo; Previous"
        },
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "We have emailed your password reset link!",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that email address."
        }
    },
    "nl": {
        "(and {count} more error)": "(and {count} more error)",
        "(and {count} more errors)": "(and {count} more errors)",
        "1 star": "1 ster",
        "1x": "1x",
        "2 stars": "2 sterren",
        "2x": "2x",
        "3 stars": "3 sterren",
        "3x": "3x",
        "4 stars": "4 sterren",
        "5 stars": "5 sterren",
        "Page not found": "Pagina niet gevonden",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{count} characters": "{count} characters",
        "About us": "Over ons",
        "Active": "Actief",
        "Add in {day}": "Toevoegen in {day}",
        "Add moment": "Voeg moment toe",
        "All rights reserved.": "Alle rechten voorbehouden.",
        "Alt text": "Alt-tekst",
        "Always show banner": "Toon altijd banner",
        "Anchor Name": "Naam anker",
        "Author": "Auteur",
        "Background image": "Achtergrondbeeld",
        "Back to home": "Terug naar home",
        "Birthday": "Geboortedag",
        "BMI": "BMI",
        "Capacity": "Capaciteit",
        "Choose a date and time": "Kies een datum en tijd",
        "City": "Stad",
        "Company name": "Bedrijfsnaam",
        "Concept in only visible to logged in users, draft is not visible to anyone": "Concept in alleen zichtbaar voor ingelogde gebruikers, concept is voor niemand zichtbaar",
        "Contact": "Contact",
        "Contact form": "Contactformulier",
        "Content": "Inhoud",
        "Create a password": "Maak een wachtwoord aan",
        "Custom": "Aangepaste",
        "Customer": "Klant",
        "Customers": "Klanten",
        "Dashboard URL": "Dashboard URL",
        "Date and Time": "Datum en tijd",
        "Date and time": "Datum en tijd",
        "Date of birth": "Geboortedatum",
        "Day": "Dag",
        "Dear {name},": "Beste {name},",
        "Description": "Beschrijving",
        "description": "beschrijving",
        "Discount": "Korting",
        "Discount active": "Korting actief",
        "E-mail": "E-mail",
        "E-mail *": "E-mail *",
        "Edit Page": "Pagina bewerken",
        "Email address": "E-mailadres",
        "End": "Einde",
        "Excerpt": "Uittreksel",
        "excerpt": "uittreksel",
        "Fat percentage": "Vet percentage",
        "Female": "Vrouw",
        "First Name": "Voornaam",
        "First name": "Voornaam",
        "Forbidden": "Geen toegang",
        "Gender": "Geslacht",
        "Google Maps URL": "Google Maps URL",
        "Google maps URL": "Google maps URL",
        "Go to page {page}": "Ga naar pagina {page}",
        "header bg": "Header achtergrondsafbeelding",
        "Hello!": "Hallo!",
        "Hey Topper, what's your name?": "Hey Topper, hoe heet je?",
        "Hi,": "Hoi,",
        "Hits": "Hits",
        "Hi [name] !": "Hoi [naam] !",
        "How can I help you?": "Hoe kan ik je helpen?",
        "How can I reach you?": "Hoe kan ik je bereiken?",
        "I am Danny, who are you?": "Ik ben danny, wie ben jij?",
        "If a interview needs to be rescheduled, either select a new timeslot or create a new one using the plus button.": "Als een intake moet worden verzet, selecteer dan een nieuwe tijdsperiode of maak er een nieuwe aan met behulp van de plusknop.",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "Indien ingeschakeld, en er is geen afbeelding geupload, dan wordt de banner van de homepage getoond.",
        "If in page block anchor ID is filled in, fill in te excact name starting with a #. For example if the block is named quote, the url that goes in here would be #quote. On click link it will scroll to this block.": "Als in het paginablok anchor ID is ingevuld, vul dan de exacte naam in die begint met een #. Als het blok bijvoorbeeld quote heet, zou de url die hier komt #quote zijn. Als u op de link klikt, gaat u naar dit blok.",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "Als je problemen hebt met de \"{actionText}\" knop, kopieer en plak de URL hierondernin je webbrowser:",
        "If you did not create an account, no further action is required.": "Als je geen account hebt aangemaakt hoef je verder niets te doen.",
        "If you did not request a password reset, no further action is required.": "Als je geen wachtwoordherstel hebt aangevraagd, hoef je verder niets te doen.",
        "Image": "Afbeelding",
        "Index Page": "Indexpagina",
        "In kilograms (ex 80,5).": "In kilogrammen (bijv. 80,5).",
        "In meters (ex 1,80).": "In meters (bijv. 1,80).",
        "Instagram": "Instagram",
        "Instagram handle": "Instagram handle",
        "Interview moment": "Intake moment",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "Het wordt aanbevolen het {attribuut} tussen {min} en {max} tekens lang te houden.",
        "I will contact you within 24 hours.": "Binnen 24 uur neem ik contact met je op.",
        "Kind regards,": "Met vriendelijke groet,",
        "Last Name": "Achternaam",
        "Last name": "Achternaam",
        "Leave empty for no end date": "Leeg laten voor geen einddatum",
        "Leave empty to use current time.": "Leeg laten om de huidige tijd te gebruiken.",
        "Leave empty to use parent details": "Leeg laten om hoofdgegevens te gebruiken",
        "Length": "Lengte",
        "Lifestyle": "Lifestyle",
        "List items": "Lijst items",
        "Logbook": "Logboek",
        "Login": "Inloggen",
        "Logout": "Uitloggen",
        "Look ahead days": "Vooruitblik dagen",
        "Lose weight": "Afvallen",
        "Made by:": "Gerealiseerd door:",
        "Male": "Man",
        "Men": "Man",
        "mobile menu bg": "mobiel menu bg",
        "Moment": "Moment",
        "Month": "Maand",
        "Motivation": "Motivatie",
        "Muscle building": "Spieropbouw",
        "Muscle percentage": "Spierpercentage",
        "My logbook": "Mijn logboek",
        "Name": "naam",
        "name": "naam",
        "New Intake": "Nieuwe intake",
        "New URL": "Nieuwe URL",
        "Next": "Volgende",
        "Not Found": "Niet gevonden",
        "of": "van",
        "Old URL": "Oude URL",
        "Openings hours": "Openingstijden",
        "Open url in new tab": "Open url in nieuw tabblad",
        "Other": "Anders",
        "Package": "Pakket",
        "Package frequency": "Pakket frequentie",
        "Package name": "Naam van het pakket",
        "Packages": "Pakketten",
        "Page Expired": "Pagina niet meer geldig",
        "Pagination Navigation": "Paginanavigatie",
        "Parent category": "Hoofdcategorie",
        "Permanent": "Permanent",
        "Phone": "Telefoon",
        "Phone number": "Telefoonnummer",
        "Phone number *": "Telefoonnummer *",
        "Please click the button below to verify your email address.": "Klik op de knop hieronder om je e-mailadres te verifiëren.",
        "Postal code": "Postcode",
        "Previous": "Vorige",
        "Price": "Prijs",
        "Price frequency": "Prijsfrequentie",
        "Privacy declaration": "Privacyverklaring",
        "Quote": "Quote",
        "Reason": "Reden",
        "Regards": "Met vriendelijke groet",
        "Register": "Registreren",
        "Reset Password": "Wachtwoord herstellen",
        "Reset Password Notification": "Wachtwoordherstel notificatie",
        "Restart": "opnieuw starten",
        "results": "resultaten",
        "Review": "Review",
        "Reviews": "Reviews",
        "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created": "Selecteer een template om de bijbehorende blokken toe te voegen. Let op: dit kan niet worden gewijzigd nadat de pagina is aangemaakt.",
        "Send": "Verzenden",
        "SEO Settings for {lang}": "SEO instellingen voor {lang}",
        "SEO title": "SEO titel",
        "Server Error": "Server fout",
        "Service Unavailable": "Website onbeschikbaar",
        "Shall we schedule an intake meeting right away?": "Zullen we meteen een intakegesprek inplannen?",
        "Showing": "Toont",
        "Show Request quote and download leaflet buttons": "Toon Offerteaanvraag en download folder knoppen",
        "Slug": "Permalink",
        "Stars": "Sterren",
        "Start": "Start",
        "Statistics": "Statistieken",
        "Steps": "Stappen",
        "Street name": "Straatnaam",
        "Sub menu will only be shown if url is not filled in": "Submenu wordt alleen getoond als de url niet is ingevuld",
        "Terms and Conditions": "Algemene Voorwaarden",
        "Text": "Tekst",
        "Thank you for filling in!": "Bedankt voor het invullen!",
        "Thank you for registering for an intake interview. I will contact you within 24 hours.": "Bedankt voor het aanmelden voor een intakegesprek. Binnen 24 uur neem ik contact met je op.",
        "Thank you for your Intake": "Bedankt voor het invullen van het intake formulier",
        "The {attribute} must contain at least one letter.": "Het {attribute} moet minimaal één letter bevatten.",
        "The {attribute} must contain at least one number.": "Het {attribute} moet minimaal één cijfer bevatten.",
        "The {attribute} must contain at least one symbol.": "Het {attribute} moet minimaal één symbool bevatten.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "Het {attribute} moet minimaal één hoofdletter en één kleine letter bevatten.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "Het {attribute} is aangetroffen in een datalek. Geef een ander {attribute}.",
        "The given data was invalid.": "De gegeven data was ongeldig.",
        "The intake form has been filled in. See all details below:": "Het intakeformulier is ingevuld. Zie alle details hieronder:",
        "This anchor ID is used for the menu to scroll to this block. Fill in a short name, without spaces.": "Deze anker-ID wordt gebruikt voor het menu om naar dit blok te bladeren. Vul een korte naam in, zonder spaties.",
        "This link expires in {number} hours": "Deze link verloopt over {number} uur",
        "This link has expired. Please request a new one.": "Deze link is verlopen. Vraag een nieuwe aan.",
        "This password reset link will expire in {count} minutes.": "Deze link om je wachtwoord te herstellen verloopt over {count} minuten.",
        "Timeslot": "Timeslot",
        "Timeslot is no longer available": "Timeslot is niet langer beschikbaar",
        "Title": "Titel",
        "title": "titel",
        "to": "tot",
        "Toggle navigation": "Schakel navigatie",
        "Too Many Requests": "Te veel serververzoeken",
        "Type": "Type",
        "Unauthorized": "Onbevoegd",
        "url": "url",
        "Variants": "Varianten",
        "Verify Email Address": "Verifieer e-mailadres",
        "Web Page": "Bewerk pagina",
        "weekly": "wekelijks",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "We hebben ons best gedaan, maar het lijkt erop dat de door u opgevraagde pagina niet (meer) bestaat of dat deze is verplaatst.",
        "Weight": "Gewicht",
        "Weight loss": "Afvallen",
        "What is your birthday?": "Wat is je geboortedatum?",
        "What is your gender?": "Wat is je geslacht?",
        "What is your motivation?": "Wat is je motivatie?",
        "When enabled, an email will be sent to the user, letting them know their account has been created.": "Indien ingeschakeld, wordt een e-mail naar de gebruiker gestuurd om hem te laten weten dat zijn account is aangemaakt.",
        "Whoops!": "Oeps!",
        "Why are you motivated to get started?": "Waarom ben je gemotiveerd om te beginnen?",
        "Woman": "Vrouw",
        "Year": "Jaar",
        "You are receiving this email because we received a password reset request for your account.": "Je ontvangt deze e-mail omdat we een wachtwoordherstel verzoek hebben ontvangen voor je account.",
        "Your account for {siteName}": "Je account voor {siteName}",
        "You will receive this email because your account is ready for {siteName}": "Je ontvangt deze e-mail omdat je account klaar is voor {siteName}",
        "validation": {
            "accepted": "{Attribute} moet worden geaccepteerd.",
            "accepted_if": "{Attribute} moet worden geaccepteerd als {other} {value} is.",
            "active_url": "{Attribute} is geen geldige URL.",
            "after": "{Attribute} moet een datum na {date} zijn.",
            "after_or_equal": "{Attribute} moet een datum na of gelijk aan {date} zijn.",
            "alpha": "{Attribute} mag alleen letters bevatten.",
            "alpha_dash": "{Attribute} mag alleen letters, nummers, underscores (_) en streepjes (-) bevatten.",
            "alpha_num": "{Attribute} mag alleen letters en nummers bevatten.",
            "array": "{Attribute} moet geselecteerde elementen bevatten.",
            "before": "{Attribute} moet een datum vóór {date} zijn.",
            "before_or_equal": "{Attribute} moet een datum vóór of gelijk aan {date} zijn.",
            "between": {
                "array": "{Attribute} moet tussen {min} en {max} waardes bevatten.",
                "file": "{Attribute} moet tussen {min} en {max} kilobytes zijn.",
                "numeric": "{Attribute} moet tussen {min} en {max} zijn.",
                "string": "{Attribute} moet tussen {min} en {max} karakters zijn."
            },
            "boolean": "{Attribute} moet ja of nee zijn.",
            "confirmed": "Bevestiging van {attribute} komt niet overeen.",
            "current_password": "Huidig wachtwoord is onjuist.",
            "date": "{Attribute} moet een datum bevatten.",
            "date_equals": "{Attribute} moet een datum gelijk aan {date} zijn.",
            "date_format": "{Attribute} voldoet niet aan het formaat {format}.",
            "declined": "{Attribute} moet afgewezen worden.",
            "declined_if": "{Attribute} moet afgewezen worden wanneer {other} gelijk is aan {value}.",
            "different": "{Attribute} en {other} moeten verschillend zijn.",
            "digits": "{Attribute} moet bestaan uit {digits} cijfers.",
            "digits_between": "{Attribute} moet bestaan uit minimaal {min} en maximaal {max} cijfers.",
            "dimensions": "{Attribute} heeft geen geldige afmetingen voor afbeeldingen.",
            "distinct": "{Attribute} heeft een dubbele waarde.",
            "doesnt_end_with": "The {attribute} may not end with one of the following: {values}.",
            "doesnt_start_with": "{Attribute} mag niet beginnen met één van de volgende waarden: {values}.",
            "email": "{Attribute} is geen geldig e-mailadres.",
            "ends_with": "{Attribute} moet met één van de volgende waarden eindigen: {values}.",
            "enum": "Gekozen {attribute} is ongeldig.",
            "exists": "{Attribute} bestaat niet.",
            "file": "{Attribute} moet een bestand zijn.",
            "filled": "{Attribute} is verplicht.",
            "gt": {
                "array": "{Attribute} moet meer dan {value} waardes bevatten.",
                "file": "{Attribute} moet groter zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet groter zijn dan {value}.",
                "string": "{Attribute} moet meer dan {value} tekens bevatten."
            },
            "gte": {
                "array": "{Attribute} moet {value} of meer waardes bevatten.",
                "file": "{Attribute} moet groter of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet groter of gelijk zijn aan {value}.",
                "string": "{Attribute} moet minimaal {value} tekens bevatten."
            },
            "image": "{Attribute} moet een afbeelding zijn.",
            "in": "{Attribute} is ongeldig.",
            "in_array": "{Attribute} bestaat niet in {other}.",
            "integer": "{Attribute} moet een getal zijn.",
            "ip": "{Attribute} moet een geldig IP-adres zijn.",
            "ipv4": "{Attribute} moet een geldig IPv4-adres zijn.",
            "ipv6": "{Attribute} moet een geldig IPv6-adres zijn.",
            "json": "{Attribute} moet een geldige JSON-string zijn.",
            "lowercase": "The {attribute} must be lowercase.",
            "lt": {
                "array": "{Attribute} moet minder dan {value} waardes bevatten.",
                "file": "{Attribute} moet kleiner zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner zijn dan {value}.",
                "string": "{Attribute} moet minder dan {value} tekens bevatten."
            },
            "lte": {
                "array": "{Attribute} moet {value} of minder waardes bevatten.",
                "file": "{Attribute} moet kleiner of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner of gelijk zijn aan {value}.",
                "string": "{Attribute} moet maximaal {value} tekens bevatten."
            },
            "mac_address": "{Attribute} moet een geldig MAC-adres zijn.",
            "max": {
                "array": "{Attribute} mag niet meer dan {max} waardes bevatten.",
                "file": "{Attribute} mag niet meer dan {max} kilobytes zijn.",
                "numeric": "{Attribute} mag niet hoger dan {max} zijn.",
                "string": "{Attribute} mag niet uit meer dan {max} tekens bestaan."
            },
            "max_digits": "The {attribute} must not have more than {max} digits.",
            "mimes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "mimetypes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "min": {
                "array": "{Attribute} moet minimaal {min} waardes bevatten.",
                "file": "{Attribute} moet minimaal {min} kilobytes zijn.",
                "numeric": "{Attribute} moet minimaal {min} zijn.",
                "string": "{Attribute} moet minimaal {min} tekens zijn."
            },
            "min_digits": "The {attribute} must have at least {min} digits.",
            "multiple_of": "{Attribute} moet een veelvoud van {value} zijn.",
            "not_in": "{Attribute} is ongeldig.",
            "not_regex": "Het formaat van {attribute} is ongeldig.",
            "numeric": "{Attribute} moet een getal zijn.",
            "password": {
                "letters": "{Attribute} moet minimaal één letter bevatten.",
                "mixed": "{Attribute} moet minimaal één kleine letter en één hoofdletter bevatten.",
                "numbers": "{Attribute} moet minimaal één cijfer bevatten.",
                "symbols": "{Attribute} moet minimaal één vreemd teken bevatten.",
                "uncompromised": "Het opgegeven {attribute} komt voor in een datalek. Kies een ander {attribute}."
            },
            "present": "{Attribute} moet aanwezig zijn.",
            "prohibited": "{Attribute} is niet toegestaan.",
            "prohibited_if": "{Attribute} is niet toegestaan indien {other} gelijk is aan {value}.",
            "prohibited_unless": "{Attribute} is niet toegestaan tenzij {other} gelijk is aan {values}.",
            "prohibits": "{Attribute} is niet toegestaan in combinatie met {other}.",
            "regex": "Het formaat van {attribute} is ongeldig.",
            "required": "{Attribute} is verplicht.",
            "required_array_keys": "{Attribute} moet waardes bevatten voor {values}.",
            "required_if": "{Attribute} is verplicht indien {other} gelijk is aan {value}.",
            "required_if_accepted": "The {attribute} field is required when {other} is accepted.",
            "required_unless": "{Attribute} is verplicht tenzij {other} gelijk is aan {values}.",
            "required_with": "{Attribute} is verplicht in combinatie met {values}.",
            "required_with_all": "{Attribute} is verplicht in combinatie met {values}.",
            "required_without": "{Attribute} is verplicht als {values} niet ingevuld is.",
            "required_without_all": "{Attribute} is verplicht als {values} niet ingevuld zijn.",
            "same": "{Attribute} en {other} moeten overeenkomen.",
            "size": {
                "array": "{Attribute} moet {size} waardes bevatten.",
                "file": "{Attribute} moet {size} kilobytes groot zijn.",
                "numeric": "{Attribute} moet {size} zijn.",
                "string": "{Attribute} moet {size} tekens zijn."
            },
            "starts_with": "{Attribute} moet beginnen met een van de volgende: {values}.",
            "string": "{Attribute} moet een tekst zijn.",
            "timezone": "{Attribute} moet een geldige tijdzone zijn.",
            "unique": "{Attribute} is al in gebruik.",
            "uploaded": "Het uploaden van {attribute} is mislukt.",
            "uppercase": "The {attribute} must be uppercase.",
            "url": "{Attribute} moet een geldige URL zijn.",
            "uuid": "{Attribute} moet een geldige UUID zijn.",
            "attributes": {
                "address": "adres",
                "age": "leeftijd",
                "amount": "bedrag",
                "area": "regio",
                "available": "beschikbaar",
                "birthday": "verjaardag",
                "body": "body",
                "city": "stad",
                "content": "inhoud",
                "country": "land",
                "created_at": "aangemaakt op",
                "creator": "maker",
                "current_password": "huidig wachtwoord",
                "date": "datum",
                "date_of_birth": "geboortedatum",
                "day": "dag",
                "deleted_at": "verwijderd op",
                "description": "omschrijving",
                "district": "wijk",
                "duration": "tijdsduur",
                "email": "e-mailadres",
                "excerpt": "uittreksel",
                "filter": "filter",
                "first_name": "voornaam",
                "gender": "geslacht",
                "group": "groep",
                "hour": "uur",
                "image": "afbeelding",
                "last_name": "achternaam",
                "lesson": "les",
                "line_address_1": "adresregel 1",
                "line_address_2": "adresregel 2",
                "message": "bericht",
                "middle_name": "tweede naam",
                "minute": "minuut",
                "mobile": "mobiel",
                "month": "maand",
                "name": "naam",
                "national_code": "landcode",
                "number": "nummer",
                "password": "wachtwoord",
                "password_confirmation": "wachtwoordbevestiging",
                "phone": "telefoonnummer",
                "photo": "foto",
                "postal_code": "postcode",
                "price": "prijs",
                "province": "provincie",
                "recaptcha_response_field": "recaptcha antwoordveld",
                "remember": "onthouden",
                "restored_at": "hersteld op",
                "result_text_under_image": "antwoord tekst onder afbeelding",
                "role": "rol",
                "second": "seconde",
                "sex": "geslacht",
                "short_text": "korte tekst",
                "size": "grootte",
                "state": "staat",
                "street": "straatnaam",
                "student": "student",
                "subject": "onderwerp",
                "teacher": "docent",
                "terms": "voorwaarden",
                "test_description": "test beschrijving",
                "test_locale": "test landinstelling",
                "test_name": "test naam",
                "text": "tekst",
                "time": "tijd",
                "title": "titel",
                "updated_at": "bijgewerkt op",
                "username": "gebruikersnaam",
                "year": "jaar"
            }
        },
        "routes": {
            "terms": "algemene-voorwaarden"
        },
        "auth": {
            "failed": "Deze combinatie van e-mailadres en wachtwoord is niet geldig.",
            "password": "Wachtwoord is onjuist.",
            "throttle": "Te veel mislukte aanmeldpogingen. Probeer het nog eens over {seconds} seconden."
        },
        "pagination": {
            "next": "Volgende &raquo;",
            "previous": "&laquo; Vorige"
        },
        "passwords": {
            "reset": "Het wachtwoord van uw account is gewijzigd.",
            "sent": "We hebben een e-mail verstuurd met instructies om een nieuw wachtwoord in te stellen.",
            "throttled": "Gelieve even te wachten en het dan opnieuw te proberen.",
            "token": "Dit wachtwoordhersteltoken is niet geldig.",
            "user": "Geen gebruiker bekend met het e-mailadres."
        }
    }
}
