export default {
    global: {
        outer: 'w-full mb-3',
        inner: 'relative',
        label: 'block mb-3 leading-none text-blue text-base',
        input: 'block w-full px-4 py-2 bg-white border border-gray-border rounded text-blue text-base focus:outline-none placeholder-gray-light focus:ring-1 focus:ring-pink focus:border-pink  min-h-10 -my-[1px] disabled:bg-gray-medium',
        help: 'text-xs text-blue mt-2',
        messages: 'list-none p-0 mt-3 mb-3 font-semi text-xs text-pink  ',
        message: 'mb-1 flex items-center gap-2 leading-tight',
        prefixIcon: 'absolute top-1/2 -translate-y-1/2 w-6 left-3 pointer-events-none',
        suffixIcon: 'absolute top-1/2 -translate-y-1/2 w-6 right-3 pointer-events-none',
        wrapper: '$reset w-full md:py-6',
        tabs: 'flex py-10 w-[240px] m-auto min-h-10',
        steps: '$reset',
        stepActions: '$reset justify-center flex flex-col md:flex-row items-center',
    },
    search: {
        input: 'pl-10 appearance-none',
    },
    button: {
        input: '$reset relative w-[240px] px-4 py-2 text-sm text-white transition-colors disabled:opacity-30 disabled:hover:bg-gray duration-300 border border-transparent uppercase font-extrabold bg-gray hover:bg-primary/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:pink min-h-10 flex-center focus:text-white',
        wrapper: 'mt-4',
    },

    checkbox: {
        input: '$reset block w-4 h-4 mr-4 rounded accent-blue-light focus:outline-none disabled:bg-gray-100',
        wrapper: 'flex items-center py-2',
        label: '$reset block leading-tight mb-0 select-none font-bold',
        decorator: 'hidden',
        legend: 'block mb-3 leading-none text-blue text-base',
    },
    select: {
        outer: 'min-w-20',
        input: 'appearance-none',
        selectIcon: 'absolute top-1/2 -translate-y-1/2 w-6 right-3 pointer-events-none',
    },
    textarea: {
        input: 'h-43',
    },
    radio: {
        legend: 'block mb-2 leading-tight font-medium',
        wrapper: 'flex items-center gap-3',
        label: '$reset relative w-full min-w-[240px] px-4 py-2 text-white transition-colors duration-300 border border-transparent uppercase font-extrabold bg-primary hover:bg-primary/80 focus:bg-primary-dark focus:ring-2 focus:ring-offset-2 min-h-10 flex-center focus:text-white',
        input: '$reset hidden',
        inner: 'h-4',
        options: 'flex  gap-2',
    },
};
